<template>
<v-card :loading="loading" class="mx-auto my-15 rounded-lg" max-width="374" height=""  outlined elevation="1">
  <input style="display:none;" @keyup.enter="Submit">
  <template slot="progress">
    <v-progress-linear color="blue-grey darken-4" height="5" indeterminate>
    </v-progress-linear>
  </template>
 
  <div style="padding-top:10px; ">
  <v-img class="mx-auto my-3" :src="require('@/assets/z.svg')" width="50"/> 
  </div>
      <v-col style="padding:0;"
      class="text-center"
      cols="12">
      <h3> Smart Pay </h3>
      <v-card-text style="padding:0; padding-bottom:10px;">
      Get instant credit to Trading Account
      </v-card-text>
      </v-col>
      
  <v-divider class="mx-5 my-1"></v-divider>
  
  <v-card-actions>
    <v-row justify="end" class="py-5 mx-3">
      <v-btn class="mx-auto" color="#377dff"  outlined v-on:click="Submit">
        Log in with Zebull
      </v-btn>
    </v-row>
  </v-card-actions>
  <v-footer padless>
  <v-col class="text-center" cols="12">
        <a :href="`tel:=+919380108010`" class="mx-4" style="text-decoration: none;" icon>
              <v-icon size="24px" color='#377dff'>
                mdi-phone
              </v-icon>
        </a>
        <a :href="`mailto:payin@zebuetrade.com`" class="mx-4" style="text-decoration: none;" icon>
              <v-icon size="24px" color='#377dff'>
                mdi-email
              </v-icon>
        </a>
    </v-col>
</v-footer>

</v-card>

</template>
<script>
  history.pushState(null, null, document.URL);
  window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL);
});
</script>
<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate'
import { required, maxLength,  } from 'vuelidate/lib/validators'
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';

export default {mixins: [validationMixin],
    validations: {
      pan_number: { required, maxLength: maxLength(10) },
      code: { required,  maxLength: maxLength(10)},
            select: { required },
       },
    data: function() {
       return {
        code: null,
        pan_number:null,
        loading: false,
        selection: 1,
        zlogo: require('@/assets/logo.svg'),
        resp: '',
        valid: true,
        clientcode_validate : null,
        pan_validate : null,
        userRules:[
          v => !!v || 'User ID is required',
        ],
        panRules:[
          v => !!v || 'PAN number is required',
        ],
        icons: [
        'mdi-help-box',
        'mdi-phone',
        'mdi-email',
      ],
    };
    
},

methods: {
   
    Submit () {
          var url='https://zebull.in/?mode=SSO&vendor=MyZebu&redirectUrl=https%3A%2F%2Fzpay.zebull.in%2F'  
        //  var url='https://zebull.in/?mode=SSO&vendor=MyZebu&redirectUrl=http://192.168.55.41:8080/'
         window.location.replace(url);
         this.loading = true;
    }
    },

    mounted(){
      
        var url = new URL(window.location.href);
        var authCode = url.searchParams.get("authCode");

        if(authCode !=null){
         //    this.$session.set("authcode",authCode);
          // console.log("authCode : ",authCode);
            this.loading = true;
        var val = this
            axios.post('https://zapi.zebull.in/ssoclient_check?code='+authCode)
  
        .then(function (response) {
            if(response.data['client_code'].length <=2 && typeof(response.data['client_check']) == 'string'){ 
                val.clientcode_validate = 1;
                val.loading = false;
                alert("Error has occurred please reload the page with a new session");
            }
            else{
                val.clientcode_validate =0;
                //console.log('Resp:',response.data['client_check']['DATA'][0][1]);
                var prop =response.data['client_code'];
                    // console.log("resssssss : ",response.data,"\nprop : ",prop)
                val.$router.replace({name:'About',params:{prop}});
            }
        })
        .catch(function (error) {
          alert("Error has occurred please reload the page with a new session ",error);
           window.location.replace('/');
           val.loading = false;
        });
      
        }
    },//mounted end
}
</script>
